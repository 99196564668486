.menu-fractal{
    height: 50px;
    width:50px;
    position: absolute;
    right:20px;
    top:5px;
    background: var(--gradTopLeft);
    border-radius:12px;
    box-shadow: 3px 3px 6px -3px rgb(var(--c0)), -3px -3px 6px -2px white;
    box-sizing: border-box;
    border-bottom:1px solid white;
    border-right: 1px solid white;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
}

.menu-fractal:hover{
    animation: menu-fractal 0.618s ease-in-out;
}

.menu-body {
    height:325px;
    width:300px;
    position: fixed;
    z-index:200;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2,1fr);
    grid-gap: 24px;
    row-gap: 24px;
    background: rgb(var(--c2));
    box-sizing: border-box;
    padding:24px;
    border-radius:12px;
    box-shadow: 0px 10px 20px -10px rgba(var(--c0),.618);
    transition: all 0.23s ease-in-out;
    animation: menu-body 0.25s ease-in-out;
}

.fractal-menu-home{
    position: absolute;
    left:50%;
    bottom:-30px;
    transform: translateX(-50%);
    display: grid;
    justify-items:center;
    background: rgb(var(--c3));
    border-radius:100%;
    box-shadow: 3px 3px 6px -3px rgb(var(--c0)), -3px -3px 6px -2px white;
}

.fractal-menu-home:hover{
    animation: fmh 0.25s ease-in-out;
} 

.menuLogo{
    position: absolute;
    margin-top:-40px;
    margin-left:-12px;
}

.menuL{
    margin-top:5px;
}

.menuL svg {
    animation: logo-spin 6s infinite linear;
}

.laby-link{
    position: absolute;
    left:50%;
    bottom:50%;
    transform: translate(-50%,40%) rotate(-5deg);
    width:70px;
    height:70px;
    background: radial-gradient(at top, white, tomato);
    border-radius:12px 6px;
    display: grid;
    align-items:center;
    justify-items:center;
    box-shadow: 0 10px 20px -10px rgb(var(--c0));
    font-family: var(--mono0);
    font-weight:bold;
    color: rgb(var(--c0));
    font-size:0.7em;
    opacity:0.8;
    transition: all 0.25s ease-in-out;
}

.laby-link span{
    transform: rotate(0deg);
    margin-top:-6px;
    font-family: var(--logo)
;}

.laby-link:hover{
    opacity:1;
    transform: translate(-50%,40%) rotate(5deg);
}

.laby-link svg {
    transition: all 0.25s ease-in-out;
}

.laby-link:hover svg {
    stroke: white;
    stroke-dasharray: 100px;
    stroke-dashoffset: 1000px;
    animation: llsvg 2s infinite linear;
}

@keyframes llsvg{
    100%{
        stroke-dashoffset:0;
    }
}

@keyframes menu-body {
    0%{
        opacity:0;
        transform: translate(-50%,-50%) scale(0.7,0.7);
    }
    100%{
        opacity:1;
        transform: translate(-50%, -50%) scale(1,1);
    }
}

@keyframes menu-fractal {
    0%{
        transform: rotate(4deg);
    }
    25%{
        transform: rotate(-3deg);
    }
    50%{
        transform: rotate(2deg);
    }
    75%{
        transform: rotate(-1deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

@keyframes fmh {
    0%{
        transform: translateX(-50%) rotate(4deg);
    }
    25%{
        transform: translateX(-50%) rotate(-3deg);
    }
    50%{
        transform: translateX(-50%) rotate(2deg);
    }
    75%{
        transform: translateX(-50%) rotate(-1deg);
    }
    100%{
        transform: translateX(-50%) rotate(0deg);
    }
}

@media (max-width:500px){
    .menu-body{
        height:270px;
        width:250px;
        transform: translate(-50%,-40%);
    }
    @keyframes menu-body {
        0%{
            opacity:0;
            transform: translate(-50%,-40%) scale(0.7,0.7);
        }
        100%{
            opacity:1;
            transform: translate(-50%, -40%) scale(1,1);
        }
    }
}
