.glyphList-body{
    margin-top:100px;
    background: rgb(230,230,225);
}

.glyphList-title{
    height:200px;
    border-radius: 0 0 200px 0;
    background: var(--gradTopLeftDark);
    font-family: var(--logo);
    color: rgb(var(--c2));
    align-items:center;
    font-size:1.5em;
    box-sizing: border-box;
    padding:24px;
    padding-top: 76px;
    text-align:center;
}

span.span-list-title{
    color: rgb(var(--c3));
}

.glyphList-list {
    position: relative;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    justify-items:center;
    grid-gap: 12px;
    margin: 0 auto;
    max-width:70%;
}

p.glyphList-presentation{
    grid-column: 1 /span all;
    font-family: var(--inter);
    color: rgb(var(--c1));
    font-weight: bold;
    max-width:50ch;
    text-align:left;
}

span.spanStrong{
    font-family: var(--logo);
    color: rgb(var(--c0));
}

.glyph-block{
    width:100px;
    display: grid;
    align-items:center;
    justify-items:center;
    box-sizing: border-box;
    padding:6px;
    background: white;
    border-radius:3px;
    box-shadow: 0 5px 10px -5px rgba(var(--c1),.3);
    font-family: var(--logo);
    color: rgb(var(--c1));
    transition: all 0.0618s ease-in-out;
    position: relative;
}

.glyph-block:hover{
    transform: scale(1.0618,1.0618);
    box-shadow: 0 10px 20px -10px rgba(var(--c1),.618);
}

.glyph-list-phono {
    font-family: var(--inter);
    font-size:0.7em;
    color: tomato;
    font-weight:bold;
}

.glyph-list-glyph {
    box-sizing: border-box;
    padding: 10px;
}

.glyph-list-glyph svg{
    height: 50px !important;
    width:50px !important;
}

.glyph-list-translation {
    font-size:0.8em;
}

.link-home {
    margin: 0 auto;
    width:100%;
    display: grid;
    align-items:center;
    justify-items:center;
    box-sizing: border-box;
    padding:48px;
    padding-top:12px;
}

.link-home p {
    font-family: var(--logo);
    color: rgb(var(--c1));
}

.fractal-link-home{
    width: 100px;
    height:100px;
    background: var(--gradTopLeftDark);
    border-radius:100%;
    box-shadow: 0 10px 20px -10px rgb(var(--c0));
    transition: all 0.25s ease-in-out;
}

.fractal-link-home:hover {
    animation: wobble 0.25s ease-in-out;
}

.capper{
    display: block;
    position: absolute;
    width:100%;
    height:100%;
    background: transparent;
    cursor: pointer;
}



@keyframes wobble {
    0%{
        transform: rotate(-4deg);
    }
    25%{
        transform: rotate(3deg);
    }
    50%{
        transform: rotate(-2deg);
    }
    75%{
        transform: rotate(1deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

@media (max-width: 500px) {
    .glyphList-list {
        grid-template-columns: repeat(auto-fit, minmax(95px, 1fr));
        margin-left:50%;
        transform: translateX(-56%);
    }
}