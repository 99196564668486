.login-body{
    position: fixed;
    height:100%;
    width:100%;
    background: var(--gradTopLeftDark);
}

.login-body-form {
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background: radial-gradient(at top left, black, rgb(54, 54, 54));
    height:230px;
    width:250px;
    border-radius:12px;
    box-shadow: 0px 10px 20px -10px black;
    color: white;
    box-sizing: border-box;
    padding:24px;
    padding-left:12px;
}

form{
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items:center;
    justify-items:center;
    row-gap:12px;
}

form input {
    background: rgb(var(--c3));
    border:none;
    height:24px;
    border-radius:3px;
    font-family: var(--logo);
    box-sizing: border-box;
    padding-left:12px;
    width:100%;
}

input.submit{
    background: rgb(var(--c2));
    box-shadow: 0 10px 20px -5px black;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    height:36px;
}

input.submit:hover{
    background: rgb(var(--c1));
}

.form-fractal,
.form-fractal-post{
    height:100%;
    width:100%;
    display: grid;
    align-items:center;
    justify-items:center;
}

.form-fractal svg,
.form-fractal-post svg {
    filter: drop-shadow(0 3px 2px black);
}

.form-fractal-post{
    animation: fractalpost 1s infinite linear;
}

@keyframes fractalpost {
    0%{
        transform: scale(1,1);
    }
    50%{
        transform: scale(1.1618,1.1618);
    }
    100%{
        transform: scale(1,1);
    }
}