.footer {
    width:100%;
    height:350px;
    box-sizing: border-box;
    padding: 48px;
    font-family: var(--inter);
    text-align:center;
    font-weight: bold;
    font-size:0.8em;
    color: rgb(var(--c1));
    background: rgb(230,230,225);
}

.footer p {
    font-family: var(--logo);
    font-size: 1.3em;
    color: rgb(var(--c1));
    transition: all 0.25s ease;
}

.footer p:hover {
    color: rgb(var(--c2));
}

.footer-end {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    text-align:left;
    max-width:300px;
    margin:0 auto;
    margin-top: 10px;
    color: rgb(var(--c1));
}

.footer-end li {
    transition: all 0.25s ease-in-out;
    width:100%;
    line-height:2em;
}

.footer-end li:hover {
    color: rgb(var(--c2));
}

li.li-graphieros{

}

.fractal-small-link {
    display: inline-block;
}

.fractal-small-link svg{
    height:30px !important;
    width:30px !important;
    position: absolute;
    margin-top:-20px;
    margin-left:-5px;
}

@media (max-width:500px) {
    .footer-end li {
        font-size:0.8em;
    }
}