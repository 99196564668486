.pangolin-body{
    display: block;
    position: fixed;
    height:100%;
    width:100%;
    background: var(--gradTopLeft);
    overflow: scroll;
}

.pangolin-body::-webkit-scrollbar{
    width:0px;
} 

.labyrinth-grid{
    position: fixed;
    height:245px;
    width:245px;
    left:50%;
    top:50%;
    transform: translate(-50%,-30%);
    display: grid;
    grid-template-columns: repeat(7,35px);
    align-items:center;
    justify-items:center;
    box-sizing: border-box;
    padding-left:36px;
}

.labyrinth-name{
    position: absolute;
    top:-60px;
    left:50%;
    transform: translateX(-50%);
    color: white;
    font-family: var(--logo);
    font-size:1.65em;
    opacity:0.8;
}

.labyrinth-week-odd,
.labyrinth-week-even{
    height:100%;
    display: grid;
    grid-template-rows: repeat(7,40.2px);
}

.labyrinth-week-even{
    left:0;
    margin-top:40.2px;
}

.fractal-labyrinth{
    cursor: pointer;
    opacity: 0.3;
    transition: all 0.25s ease-in-out;
}

.fractal-labyrinth:hover{
    transform: scale(1.1,1.1);
    opacity:1;
}

.fractal-labyrinth:hover svg {
    filter: drop-shadow(0 2px 2px rgb(var(--c0)));
}

.fractal-labyrinth:hover circle{
    fill:tomato;
}

.fractal-labyrinth:hover path{
    fill:tomato;
}

.visible-day{
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-30%);
    height:300px;
    width:280px;
    background: radial-gradient(at top left, rgb(var(--c3)), white);
    color: rgb(var(--c0));
    box-sizing: border-box;
    padding:20px;
    border-radius:6px;
    box-shadow: 0 10px 20px -10px rgb(var(--c0));
    font-family: var(--inter);
    font-size:0.9em;
    text-align: justify;
    animation: visible-day 0.25s ease-in;
}

@keyframes visible-day {
    0%{
        opacity:0;
    }
    100%{
        opacity:1;
    }
}

.visible-day::-webkit-scrollbar{
    width:0px;
}

.btn-quit-day{
    position: absolute;
    top:-15px;
    right:-15px;
    border-radius:100%;
    height:30px;
    width:30px;
    background: tomato;
    box-shadow: 0 5px 10px -5px rgb(var(--c0));
    cursor: pointer;
    opacity:0.8;
    transition: all 0.25s ease-in-out;
}

.btn-quit-day:hover{
    opacity:1;
}

p.numDay{
    font-family: var(--logo);
    color: rgb(var(--c0));
    font-size:0.8em;
    width:100%;
    border-bottom:1px solid tomato;
    box-sizing: border-box;
    padding-bottom:6px;
    margin-top:-2px;
}

p.numDay span {
    color: tomato;
}

span.labySpanDate{
    background: tomato;
    color:white !important;
    font-size:0.7em;
    display: inline-block;
    position: absolute;
    right:20px;
    top:28px;
    font-family: var(--logo);
    box-sizing: border-box;
    padding-left: 3px;
    padding-right:3px;
    border-bottom:1px solid tomato;
}

.textDay{
    position: relative;
    height:85%;
    width:100%;
    overflow-x: hidden;
    box-sizing: border-box;
    text-indent:24px;
}

.textDay::-webkit-scrollbar{
    width:1px;
}

.textDay::-webkit-scrollbar-thumb{
    width:3px;
    background: tomato;
}

.textDay::-webkit-scrollbar-track{
    background: lightgrey;
}

.watermark{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-40%);
    opacity:0.6;
}

#lab-0 path, #lab-0 circle,
#lab-1 path, #lab-1 circle,
#lab-2 path, #lab-2 circle,
#lab-3 path, #lab-3 circle,
#lab-4 path, #lab-4 circle,
#lab-5 path, #lab-5 circle,
#lab-6 path, #lab-6 circle,
#lab-7 path, #lab-7 circle,
#lab-8 path, #lab-8 circle,
#lab-9 path, #lab-9 circle,
#lab-10 path, #lab-10 circle,
#lab-11 path, #lab-11 circle,
#lab-12 path, #lab-12 circle,
#lab-13 path, #lab-13 circle,
#lab-14 path, #lab-14 circle,
#lab-15 path, #lab-15 circle,
#lab-16 path, #lab-16 circle,
#lab-17 path, #lab-17 circle,
#lab-18 path, #lab-18 circle,
#lab-19 path, #lab-19 circle,
#lab-20 path, #lab-20 circle,
#lab-21 path, #lab-21 circle,
#lab-22 path, #lab-22 circle,
#lab-23 path, #lab-23 circle,
#lab-24 path, #lab-24 circle{
    animation: lab 1s linear;
    animation-iteration-count: 1;
}

#lab-24 path, #lab-24 circle{
   animation-delay:0.5s;
}
#lab-3 path, #lab-3 circle{
    animation-delay:1s;
}
#lab-16 path, #lab-16 circle{
    animation-delay:1.5s;
}
#lab-19 path, #lab-19 circle{
    animation-delay:2s;
}
#lab-7 path, #lab-7 circle{
    animation-delay:2.5s;
}
#lab-1 path, #lab-1 circle{
    animation-delay:3s;
}
#lab-9 path, #lab-9 circle{
    animation-delay:3.5s;
}
#lab-12 path, #lab-12 circle{
    animation-delay:4s;
}
#lab-20 path, #lab-20 circle{
    animation-delay:4.5s;
}
#lab-2 path, #lab-2 circle{
    animation-delay:5s;
}
#lab-11 path, #lab-11 circle{
    animation-delay:5.5s;
}
#lab-10 path, #lab-10 circle{
    animation-delay:6s;
}
#lab-18 path, #lab-18 circle{
    animation-delay:6.5s;
}
#lab-22 path, #lab-22 circle{
    animation-delay:7s;
}
#lab-4 path, #lab-4 circle{
    animation-delay:7.5s;
}
#lab-8 path, #lab-8 circle{
    animation-delay:8s;
}
#lab-14 path, #lab-14 circle{
    animation-delay:8.5s;
}
#lab-17 path, #lab-17 circle{
    animation-delay:9s;
}
#lab-23 path, #lab-23 circle{
    animation-delay:9.5s;
}
#lab-5 path, #lab-5 circle{
    animation-delay:10s;
}
#lab-13 path, #lab-13 circle{
    animation-delay:10.5s;
}
#lab-6 path, #lab-6 circle{
    animation-delay:11s;
}
#lab-15 path, #lab-15 circle{
    animation-delay:11.5s;
}
#lab-21 path, #lab-21 circle{
    animation-delay:12s;
}

.whatDay{
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-550%);
    font-family: var(--mono0);
    color: tomato;
    text-shadow: 0 1px 1px white;
    transition: all 0.25s ease-in-out;
    animation: whatDay 0.25s ease-in-out;
}

@keyframes whatDay {
    0%{
        opacity:0;
        transform: translate(-50%,-550%) scale(0,0);
    }
    100%{
        opacity:1;
        transform: translate(-50%,-550%) scale(1,1);
    }
}

@keyframes lab {
    0%{
        stroke: white;
        fill: white;
    }
    50%{
        stroke: tomato;
        fill: tomato;
        transform: scale(1.1,1.1);
    }
    100%{
        stroke: white;
        fill: white;
    }
}