.header{
    display: flex;
    height:61.8px;
    width:100%;
    position: fixed;
    top:0;
    left:0;
    background: rgb(210,210,205);
    z-index:100;
}

.sub-header{
    display: flex;
    height: 38.19px;
    width:100%;
    position: fixed;
    top: 61.8px;
    left:0;
    background: linear-gradient(to right, rgb(var(--c1)), transparent);
    z-index:100;
}

button.button-search-shape{
    height:30px;
    margin-top:4px;
    margin-left:6px;
    border-top:1px solid rgb(var(--c3));
    border-left: 1px solid rgb(var(--c3));
    border-right: 1px solid rgb(var(--c2));
    border-bottom: 1px solid rgb(var(--c2));
    border-radius:12px;
    width:150px;
    font-family: var(--inter);
    background: rgb(var(--c1));
    color: white;
    font-size:1.04em;
    line-height:1.0em;
    cursor: pointer;
    box-shadow: 0 2px 4px -2px rgb(var(--c0));
    transition: all 0.23s ease-in-out;
}

button.button-search-shape:hover {
    background: rgb(var(--c2));
    color: rgb(var(--c0));
}

.search-choice{
    display: grid;
    align-items:center;
    justify-items:center;
    height:100px;
    width:210px;
    position: fixed;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    background: white;
    border-radius:6px;
    box-sizing: border-box;
    padding:12px;
    row-gap:12px;
    background: radial-gradient(at top left,rgb(var(--c3)), rgb(var(--c1)));
    box-shadow: 0 0 20px black;
    animation: search-choice 0.25s ease-in-out;
}

@keyframes search-choice {
    0%{
        opacity:0;
    }
    100%{
        opacity:1;
    }
}

.search-choice button {
    width:100%;
    height:100%;
    cursor: pointer;
    font-family: var(--logo);
    border:none;
    border-radius:3px;
    background: rgb(var(--c3));
    box-shadow: 0 5px 10px -5px rgba(var(--c1));
    color: rgb(var(--c0));
    text-align:left;
    transition: all 0.15s ease-in-out;
    display: grid;
    grid-template-columns: repeat(2,auto);
    align-items:center;
    justify-items:center;
    transition: all 0.25s ease-in-out;
}

.search-choice button:hover {
    background: white;
    color: rgb(var(--c0));
    box-shadow: 0 10px 20px -10px rgba(var(--c0));
}

.search-choice button:hover .flatfrac-opt{
    animation: flatfrac 2s infinite;
}

@keyframes flatfrac {
    0% {
        transform: rotate(0deg);
      }
    50%{
        transform: rotate(180deg) scale(0.618,0.618)
    }  
    100% {
        transform: rotate(360deg) scale(1,1);
      }
}

@media (max-width:500px) {

    button.button-search-shape{
        font-size:0.8em;
        width:140px;
    }
}