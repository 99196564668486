.paragraphs-body{
    display: block;
    position: fixed;
    height:100%;
    width:100%;
    background: var(--gradTopLeft);
}

p.paragraph-generator-title{
    width:100%;
    max-width:400px;
    left:50%;
    transform: translateX(-50%);
    text-align:right;
    position: fixed;
    top:120px;
    font-family: var(--logo);
    color: rgb(var(--c0));
    font-size:24px;
}

p.paragraph-generator-title::after{
    width:100%;
    max-width:400px;
    display: block;
    content:'composer';
    margin-top: -10px;
    font-size: 12px;
    color: rgb(var(--c3));
    margin-right:-68px;
}

@media (max-width:500px){
    p.paragraph-generator-title{
        top:80px;
        max-width:300px;
    }
    p.paragraph-generator-title::after{
        max-width:300px;
    }
}