.landing-body-wrapper{
    background: rgb(var(--c0));
}

.landing-body{
    width:100%;
    padding:18px;
    font-family: var(--inter);
}

.landing-body,
.graphieros-physics,
.graphieros-works,
.landing-links{
    font-family: var(--inter);
}

.landing-body{
    margin-top:100px;
    box-sizing: border-box;
    padding-top:48px;
    padding-bottom: 192px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items:center;
    justify-items:center;
    grid-gap: 24px;
    color: rgb(var(--c0));
    background: var(--gradTopLeftDark);
    border-radius: 0 0 200px 0;
}

.graphieros-physics{
    background: rgb(var(--c0));
    padding-top: 96px;
    padding-bottom: 64px;
}

.graphieros-physics p,
.landing-links p {
    margin: 0 auto;
    max-width:50ch;
    font-size: 1.2em;
    box-sizing: border-box;
    padding:24px;
}

.graphieros-physics p {
    color: rgb(var(--c2));
}

.landing-fractal,
.landing-fractal svg {
    height:192px;
    width:192px;
}

.landing-fractal{
    margin-top: -224px;
    position: absolute;
    left:50%;
    transform: translateX(-50%);
    background: radial-gradient(white, rgb(var(--c2)));
    border-radius:100%;
    transition: all 0.25s ease-in-out;
    box-shadow: -10px -10px 20px 0px rgba(255, 255, 255, 0.205), 10px 10px 20px 0px rgb(var(--c0));
}

.landing-fractal svg {
    animation: landing-fractal 0.25s ease-in-out;
    filter: drop-shadow(0 3px 3px rgb(var(--c2)));
    transition: all ease-in-out;
}

p.landing-trad{
    width:100%;
    text-align:center;
    margin-top:-30px;
    font-family: var(--logo);
    color: rgb(var(--c3));
    font-size:0.9em;
}

p.landing-trad span {
    font-family: var(--inter);
}

@keyframes landing-fractal {
    0%{
        opacity:0;
        stroke: tomato;
        path: tomato;
        transform: scale(0.7,0.7);
    }
    100%{
        opacity:1;
        transform: scale(1.1,1.1)
    }
}

h1.wtf,
h1.wtf2{
    font-family: var(--logo);
    color: rgb(var(--c3));
    font-weight: normal;
}

h1.wtf{
    color: rgb(var(--c3));
}

h1.wtf2{
    color: rgb(var(--c2));
    width:100%;
    text-align:center;
}

.graphieros-presentation{
    box-sizing: border-box;
    padding:12px;
    border-radius:12px;
    width:100%;
    max-width:50ch;
    font-size:1.2em;
    color:white;
    font-family: var(--inter);
}

span.greySpan{
    color: rgba(var(--c2));
}

span.blueSpan{
    color: rgb(var(--c1));
}

.graphieros-works {
    display: block;
    position: relative;
    background: radial-gradient(at bottom left, white,rgb(210,210,205));
    border-radius: 200px 0px 200px 0;
    padding: 18px;
    padding-top: 48px;
    padding-bottom: 48px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items:center;
    justify-items:center;
    grid-gap: 24px;
    z-index:1;
    box-shadow: 20px 20px 40px -20px rgba(var(--c0),0.6);
}

.graphieros-works p {
    margin: 0 auto;
    max-width:50ch;
    color: rgb(var(--c1));
    font-size: 1.2em;
    box-sizing: border-box;
    padding:24px;
}

.graphieros-works-linear {
    width:100%;
    text-align:center;
}

.landing-links {
    box-sizing: border-box;
    padding-top: 250px;
    padding-bottom: 96px;
    margin-top:-200px;
    z-index:0;
    background: white;
    color: rgb(var(--c1));
}

.landing-links-wrapper{
    display: grid;
    grid-gap: 12px;
    row-gap:12px;
    grid-template-columns: repeat(2,120px);
    grid-template-rows: repeat(2, 120px);
    margin: 0 auto;
    width:250px;
}

.button-description-right,
.button-description-left {
    height:100%;
    width:100%;
    display: grid;
    align-items:center;
    justify-items:center;
    font-size:0.8em;
    font-weight: bold;
    box-sizing: border-box;
    padding:6px;
}

.button-description-left {
    text-align: right;
}

.abeilles{
    margin-top:36px;
    height:100px;
    width:100px;
    margin-left:50%;
    transform: translateX(-50%);
}

svg.abeilles-svg {
    opacity:.8;
    stroke:rgb(184, 184, 184);
    fill:none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width:6px;
    filter: drop-shadow(0 3px 1px black);
    stroke-dasharray: 800px;
    stroke-dashoffset: 800px;
    animation: abeil 4s linear infinite;
} 

@keyframes abeil {
    100%{
        stroke-dashoffset: 0px;
    }
}

path.ka{
    stroke: rgb(255, 132, 111);
}

path.hea{
    stroke: rgb(253, 230, 95);
}

path.snoi{
    stroke: rgb(126, 126, 255);
}

span.inLink{
    font-weight:bold;
    transition: color 0.25s ease-in-out;
}

span.inLink:hover{
    color: tomato;
}

.haiku-today{
    width:100%;
    background: var(--gradTopLeft);
    box-sizing: border-box;
    padding-top: 24px;
    padding-bottom: 64px;
}

.haiku-today p {
    width:100%;
    text-align:center;
    color: rgb(207, 207, 207);
    font-family:  var(--hand1);
    font-size:2em;
}

.haiku-translation {
    display: block;
    margin-top: 40px;
}

.haiku-translation p{
    font-style: italic;
    color: rgb(var(--c0));
    font-family: var(--inter);
    font-size:1em;
}

button.other-haiku{
    margin-top: 24px;
    margin-left:50%;
    transform: translateX(-50%);
    border:none;
    box-sizing: border-box;
    padding:12px;
    padding-left:18px;
    padding-right:18px;
    cursor: pointer;
    box-shadow: 0 10px 20px -10px rgb(var(--c1));
    border-radius:3px;
    font-family: var(--logo);
    color: rgb(var(--c1));
    background: rgb(var(--c3));
}

button.other-haiku:hover{
    animation: other-haiku 1s infinite linear;
}

.link-LeLivre-wrapper{
    background: white;
    margin-top:-2px;
}

.link-LeLivre{
    background: radial-gradient(at top left, black, rgb(var(--c0)));
    border-radius:200px 0 0 0;
    width:100%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    align-items:center;
    justify-items:center;
    box-sizing: border-box;
    padding-top:48px;
    padding-bottom:48px;
    transition: all 0.25s ease-in-out;
    cursor:pointer;
}

.link-LeLivre svg {
    transition: all 0.25s ease-in-out;
    filter: drop-shadow(5px 5px 5px black);
}

.link-LeLivre-fractal{
    grid-column: 3;
}

p.link-LeLivre-description{
    grid-column:2;
    grid-row:1;
    color: rgb(var(--c2));
    font-family: var(--logo);
    text-align:right;
}

p.link-LeLivre-description span{
    font-size:2em;
    color:rgb(var(--c3));
}

p.link-LeLivre-description{
    transition: all 0.25s ease-in-out;
}

.link-LeLivre:hover p{
    transform: scale(1.1,1.1);
}

.link-LeLivre:hover .link-LeLivre-fractal svg{
    animation: llf 1.61803398875s infinite linear;
}

@keyframes llf {
    0%{
        transform: scale(1,1);
    }
    50%{
        transform: scale(1.1,1.1);
    }
    100%{
        transform: scale(1,1);
    }
}

@keyframes other-haiku{
    0%{
        transform: translateX(-50%) scale(1,1);
    }
    50%{
        transform: translateX(-50%) scale(1.0618,1.0618);
    }
    10%{
        transform: translateX(-50%) scale(1,1);
    }
}

@media (max-width:700px){
    .landing-body,
    .graphieros-works,
    .graphieros-physics,
    .landing-links{
        display: block;
        font-size: 0.9em;
    }
    .link-LeLivre{
        grid-template-columns: repeat(1,1fr);
    }
    .link-LeLivre svg {
        height:200px;
        width:200px;
    }
    .link-LeLivre-fractal,
    p.link-LeLivre-description{
        grid-column: 1;
    }
    p.link-LeLivre-description{
        text-align:right;
    }
}
