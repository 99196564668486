:root{
  --c0:29,55,104;
  --c1:75,106,160;
  --c2:122,161,216;
  --c3:211,227,252;
  --gold:255,215,0;
  --gradTop: radial-gradient(at top, rgb(var(--c0)), rgb(var(--c1)), rgb(var(--c2)), rgb(var(--c3)));
  --gradTopLeft: radial-gradient(at top left, rgb(var(--c0)), rgb(var(--c1)), rgb(var(--c2)), rgb(var(--c3)));
  --gradTopLight: radial-gradient(at top, rgb(var(--c2)), rgb(var(--c0)));
  --gradTopLeftDark: radial-gradient(at top left, rgb(var(--c0)), rgb(var(--c1)), rgb(var(--c2)));
  --inter: 'Inter', sans-serif;
  --megrim: 'Megrim', cursive;
  --logo: 'Righteous', cursive;
  --elite: 'Special Elite', cursive;
  --hand0: 'Monsieur La Doulaise', cursive;
  --hand1:  'Mr Dafoe', cursive;
  --mono0: 'Anonymous Pro', monospace;
  --mono1: 'Nova Mono', monospace;
}

body{
  background: rgb(230,230,225);
}

.hidden{
  display:none;
}

a,
a.link{
  text-decoration: none;
  color: inherit;
}

.backState{
  height:100%;
  width:100%;
  position: fixed;
  top:0;
  left:0;
  background: radial-gradient(rgba(var(--c1),0.5),black);
}