.graphierosEditor-init{
    display: block;
    position:fixed;
    bottom: 0;
    width:96%;
    height:61.8px;
    background: black;
    z-index:200;
    box-shadow: -5px -10px 10px -10px black;
    border-radius: 0 24px 24px 0;
    animation: show-editor 0.25s ease-in-out;
    color: white;
}

.graphierosEditor-init textarea{
    width:80%;
    height:40px;
    position: absolute;
    right:20px;
    top:7px;
    border-radius:0 24px 24px 0;
    resize:none;
    font-family: var(--elite);
    color: greenyellow;
    background: black;
    border:none;
}

.reduce{
    display: block;
    position:fixed;
    bottom: 0;
    width:61.6px;
    height:61.8px;
    background: black;
    z-index:200;
    cursor: pointer;
    border-radius:0 12px 12px 0;
    box-shadow: -5px -10px 10px -10px black;
    color: transparent;
}

.reduce textarea{
    display:none;
} 

.reduce::before{
    display: block;
    margin-top: 10px;
    margin-left:30%;
    content:"_";
    color:greenyellow;
    font-family: var(--elite);
    animation: reduce 1s infinite;
    font-size:2em;
}

@keyframes reduce{
    0%{
        content: "_";
        opacity:1;
    }
    50%{
        color:tomato;
        content:"_";
        opacity:0;
    }
    100%{
        content:"_";
        opacity:1;
    }
}

.btn-hide-editor{
    position: fixed;
    bottom:0;
    z-index:201;
    height:61.8px;
    width:61.8px;
    background: transparent;
    cursor: pointer;
}

.fractal-reduce{
    height:61.8px;
    width:61.8px;
}

.fractal-reduce:hover {
    animation: wobble 0.25s ease-in-out;
}

.graphieros-terminal{
    display: block;
    position: fixed;
    width:250px;
    height:80%;
    bottom:100px;
    background: black;
    border-radius:24px;
    left:50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    padding:24px;
    overflow: scroll;
    text-align:center;
    max-width:500px;
}

.graphieros-terminal::-webkit-scrollbar{
    width:0px;
}

.terminal-close-button{
    bottom:70px;
    position: fixed;
    left:50%;
    transform:translateX(-50%);
    background: black;
    height:32px;
    width:32px;
    border-radius:100%;
    box-shadow: 5px 10px 10px -10px black;
    cursor: pointer;
    z-index:300;
}

.terminal-close-button:hover{
    animation: tcb 0.25s ease-in-out;
}

.mode-toggles{
    z-index: 200;
    position: fixed;
    left:50%;
    transform: translateX(-50%);
    bottom: 100px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
}

button.toggle-graphieros-mode{
    border:none;
    font-family: var(--logo);
    background: black;
    box-sizing: border-box;
    padding: 5px 10px;
    cursor: pointer;
    border:1px solid black;
    transition: all 0.25s ease-in-out;
}

.btn-linear{
    color: greenyellow;
    border-radius: 30px 3px 3px 30px;
    border:1px solid black;
}

.btn-linear:hover{
    box-shadow: 0 0px 15px -5px  greenyellow;
    color: green;
    border-left: 1px solid white;
}

.btn-fractal{
    color: rgb(var(--c2));
    border-radius:3px 30px 30px 3px;
    border:1px solid black;
}

.btn-fractal:hover{
    box-shadow: 0 0px 15px -5px  rgb(var(--c2));
    color: rgb(var(--c1));
    border-right: 1px solid white;
}

.btn-molecular{
    color: white;
    border-radius: 3px;
    border:1px solid black;
}

.btn-molecular:hover{
    box-shadow: 0 0px 15px -5px white;
    color: rgb(180, 180, 180);
    border-left: 1px solid white;
    border-right: 1px solid white;
}

.molecule-output{
    height:100px;
    width:100px;
    margin-top:-10px;
    margin-left:50%;
    transform:translateX(-50%);
}

.fractal-output svg {
  height:150px;
  width:150px;
  margin-top:-20px;
}

.translation-list{
    display: block;
    position: absolute;
    left: 10px;
    top: 40px;
    font-family: var(--logo);
    color: grey;
    text-align:left;
}

@keyframes show-editor{
    0%{
        width:61.8px;
    }
    100%{
        width:96%;
    }
}

@keyframes tcb{
    0%{
        transform: translateX(-50%) rotate(-10deg);
    }
    25%{
        transform: translateX(-50%) rotate(9deg);
    }
    50%{
        transform: translateX(-50%) rotate(-8deg);
    }
    75%{
        transform: translateX(-50%) rotate(7deg);
    }
    100%{
        transform: translateX(-50%) rotate(0deg);
    }
}

span.current-word{
    display: block;
    position: sticky;
    margin-left:50%;
    top:0px;
    box-sizing: border-box;
    background: black;
    transform: translateX(-50%);
    font-family: var(--elite);
    color: tomato;
    text-align:center;
    border-radius:3px;
}

@media (max-width:500px){
    button.toggle-graphieros-mode{
        font-size:0.7em;
    }
}