.paragraph-generator-body{
    width:100%;
    max-width:400px;
    height: 400px;
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    margin-bottom: 50px;
    background: white;
    z-index:100;
    border-radius:3px 48px 3px 3px;
    box-shadow: 0 10px 20px -10px rgb(var(--c0));
    box-sizing: border-box;
    padding:12px;
}

.paragraph-generator-controls{
    left:50%;
    transform:translateX(-50%);
    width:91%;
    position: absolute;
    bottom:12px;
    height:48px;
    display: grid;
    grid-template-columns: repeat(2,auto);
    grid-gap: 12px;
}

.paragraph-generator-lines {
    position: absolute;
    top:12px;
    height:266px;
    width:91%;
    left:50%;
    transform: translateX(-50%);
    border-radius:3px;
}

.paragraph-generator-lines div {
    height:100%;
}

textarea.paragraph-generator-textarea{
    resize:none;
    height:100%;
    width:100%;
    box-sizing: border-box;
    padding:6px;
    padding-left:12px;
    border:1px solid grey;
    border-radius: 3px;
    color: rgb(var(--c1));
    font-family: var(--logo);
    box-shadow: 2px 2px 8px 1px rgb(var(--c0)) inset;
}

.paragraph-generator-clear{
    display: grid;
    align-items:center;
    justify-items:center;
    text-align:center;
    height:100%;
    width:100%;
    box-sizing: border-box;
    border-radius:3px;
    background: var(--gradTopLeftDark);
    color:white;
    cursor: pointer;
    opacity:0.8;
    transition: opacity 0.25s ease-in-out;
    font-family: var(--logo);
    box-shadow: 0 5px 10px -5px rgb(var(--c0));
}

.paragraph-generator-clear:hover{
    opacity:1;
}

input.paragraph-generator-color{
    position: absolute;
    bottom:12px;
    left: -40px;
    cursor: pointer;
    height:40px;
    width:40px;
    border:none;
    background: none;
    box-sizing: border-box;
}

.svg-cypher{
    width:100%;
    position: absolute;
    background: radial-gradient(at top left, black, rgb(var(--c0)));
    height:50px;
    bottom: 60px;
    transition: all 0.25s ease-in-out;
    box-sizing: border-box;
    padding: 12px;
    font-family: var(--mono0);
    color: rgb(var(--c3));
    border-radius:3px;
    cursor: text;
    box-shadow: 0 10px 20px -10px rgb(var(--c0));
    font-size:0.6em;
    overflow: scroll;
    user-select:all;
}

.svg-cypher::-webkit-scrollbar{
    width:0px;
}

@media (max-width:500px){
    .paragraph-generator-body{
        max-width:300px;
        height:350px;
        margin-top:35px;
    }
}