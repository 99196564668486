button.menu-button,
button.menu-button-landing{
    border:none;
    border-radius:12px;
    box-shadow: 5px 5px 10px rgb(var(--c0)), -5px -5px 10px rgba(255, 255, 255, 0.5);
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    display: grid;
    align-items:center;
    justify-items:center;
    height:100%;
    width:100%;
    max-height:120px;
    max-width:120px;
}

button.menu-button{
    background: rgb(var(--c1));
}

button.menu-button-landing{
    background: var(--gradTopLight);
}

button.menu-button:hover,
button.menu-button-landing:hover{
    animation: button 0.25s ease-in-out;
}

button.menu-button:hover{
    background: rgb(59, 83, 126);
}

button.menu-button:hover p,
button.menu-button-landing:hover p{
    color: rgb(255, 130, 108);
}

button.menu-button:hover svg path{
    fill: rgb(255,130,108) !important;
}

button.menu-button:hover svg circle{
    fill: rgb(255,130,108) !important;
}

.menu-fractal-choice,
.menu-fractal-landing{
    height:60px;
    width:60px;
}

.menu-fractal-landing{
    margin-top:10px;
}

p.button-label,
p.button-label-landing{
    color: rgb(var(--c3));
    font-family: var(--logo);
    font-size:1.2em;
}

p.button-label{
    margin-top:-10px;
}

p.button-label-landing {
    margin-top:-20px !important;
}



@keyframes button {
    0%{
        transform: rotate(-4deg);
    }
    25%{
        transform: rotate(3deg);
    }
    50%{
        transform: rotate(-2deg);
    }
    75%{
        transform: rotate(1deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

@media (max-width:500px){
    p.button-label{
        font-size:1em;
        margin-top:-5px;
    }
}