.kio-idea{
    width:100%;
    max-width:300px;
    margin-left:50%;
    transform: translateX(-50%);
    background: radial-gradient(at top, rgba(255, 255, 255, 0.948), rgba(255, 255, 255, 0.251));
    margin-top:74px;
    display: block;
    height:200px;
    border-radius:6px;
    box-shadow: 0 10px 20px -10px rgba(var(--c1),0.6),0px -15px 30px -15px rgba(255, 255, 255, 0.651);
}

.kio-idea-frac,
.kio-idea-frac-landing{
    position: absolute;
    left:50%;
    top:-50px;
    transform: translateX(-50%);
    background: radial-gradient(at top,white, rgb(var(--c1)));
    border-radius:100%;
    height: 100px;
    width:100px;
    box-shadow: 0px 15px 30px -15px rgb(var(--c0)), 0px -15px 30px -15px rgba(255, 255, 255, 0.948);
}

.haiku-today .kio-molecule svg{
    animation: kifl 0.25s ease-in;
}

.kio-idea-frac-landing svg{
    animation: kifl 0.25s ease-in;
}

@keyframes kifl{
    0%{
        opacity:0;
        transform:scale(0.618,0.618);
        stroke: tomato;
    }
    100%{
        opacity:1;
        transform:scale(1.1618,1.1618);
    }
}

.white-margin{
    width: 98%;
    height: 98%;
    width: calc(100% - 2em);
    height: calc(100% - 2em);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.404), transparent);
    display: block;
    position: absolute;
    border-radius: 3px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.712);
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    box-shadow: 0 0 10px 1px rgba(var(--c1),.6) inset;
}

/*try rotating that svg*/


@media (max-width: 500px){
    .white-margin{
        display: none;
    }
}